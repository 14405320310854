.nav-menu ul.submenu {
  display: block;
}

.page-sidebar {
  height: 100%;
}

.nav-link-text {
  text-transform: capitalize;
}
