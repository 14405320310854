.help-text_warper {
  p {
    font-size: 9px;
    color: #737373;
    text-transform: capitalize;
    margin-bottom: -0.3rem;
    font-weight: lighter;
    margin-top: 0.2rem;
  }
}
