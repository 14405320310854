.input-group.date-time {
  flex-wrap: nowrap;
}
.input-group.date-time #DateRangePickerContainer {
  width: 100%;
}
.vega-embed {
  width: 100%;
}
.chart-wrapper {
  width: 100%;
}

.vegaContainer {
  width: 95%;
}
