@font-face {
  font-family: 'nextgen-icons';
  src: url('../webfonts/nextgen-icons.eot');
  src: url('../webfonts/nextgen-icons.eot?#iefix') format('embedded-opentype'),
    url('../webfonts/nextgen-icons.woff2') format('woff2'),
    url('../webfonts/nextgen-icons.woff') format('woff'),
    url('../webfonts/nextgen-icons.ttf') format('truetype'),
    url('../webfonts/nextgen-icons.svg#nextgen-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Use the following CSS code if you want to have a class per icon */
.#{$cust-icon-prefix},
[data-icon]:before,
[class^='base-'],
[class*=' base-'] {
  font-family: 'nextgen-icons';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

/* why?
.page-content {
	.#{$cust-icon-prefix}:before,
	[data-icon]:before {
		vertical-align: -8%;
	}
}*/
.navbar-toggler {
  .#{$cust-icon-prefix}:before,
  [data-icon]:before {
    vertical-align: inherit !important;
  }
}

.base-19:before {
  content: '\e09b';
}
.base-18:before {
  content: '\e09c';
}
.base-17:before {
  content: '\e09d';
}
.base-16:before {
  content: '\e09e';
}
.base-15:before {
  content: '\e09f';
}
.base-14:before {
  content: '\e0a0';
}
.base-13:before {
  content: '\e0a1';
}
.base-12:before {
  content: '\f000';
}
.base-11:before {
  content: '\f00b';
}
.base-10:before {
  content: '\f00a';
}
.base-9:before {
  content: '\f1c8';
}
.base-8:before {
  content: '\f1e3';
}
.base-7:before {
  content: '\f413';
}
.base-6:before {
  content: '\f412';
}
.base-5:before {
  content: '\f411';
}
.base-4:before {
  content: '\f410';
}
.base-3:before {
  content: '\f409';
}
.base-2:before {
  content: '\f408';
}
.base-1:before {
  content: '\f405';
}
.md-profile:before {
  content: '\f407';
}
.md-picframe:before {
  content: '\f406';
}
.#{$cust-icon-prefix}-phone:before {
  content: '\f402';
}
.#{$cust-icon-prefix}-calendar-fine:before {
  content: '\f403';
}
.#{$cust-icon-prefix}-blog-read:before {
  content: '\f404';
}
.#{$cust-icon-prefix}-my-apps:before {
  content: '\f328';
}
.#{$cust-icon-prefix}-big-plus:before {
  content: '\f276';
}
.#{$cust-icon-prefix}-minify-nav:before {
  content: '\f254';
}
.#{$cust-icon-prefix}-lock-nav:before {
  content: '\f2ae';
}
.#{$cust-icon-prefix}-chevron-down:before {
  content: '\f2f9';
}
.#{$cust-icon-prefix}-chevron-up:before {
  content: '\f2fc';
}
.#{$cust-icon-prefix}-chevron-left:before {
  content: '\f2fa';
}
.#{$cust-icon-prefix}-chevron-right:before {
  content: '\f2fb';
}
.#{$cust-icon-prefix}-menu:before {
  content: '\e000';
}
.#{$cust-icon-prefix}-user-follow:before {
  content: '\e002';
}
.#{$cust-icon-prefix}-user-following:before {
  content: '\e003';
}
.#{$cust-icon-prefix}-user-unfollow:before {
  content: '\e004';
}
.#{$cust-icon-prefix}-trophy:before {
  content: '\e006';
}
.#{$cust-icon-prefix}-screen-smartphone:before {
  content: '\e010';
}
.#{$cust-icon-prefix}-screen-desktop:before {
  content: '\e011';
}
.#{$cust-icon-prefix}-plane:before {
  content: '\e012';
}
.#{$cust-icon-prefix}-notebook:before {
  content: '\e013';
}
.#{$cust-icon-prefix}-moustache:before {
  content: '\e014';
}
.#{$cust-icon-prefix}-mouse:before {
  content: '\e015';
}
.#{$cust-icon-prefix}-magnet:before {
  content: '\e016';
}
.#{$cust-icon-prefix}-energy:before {
  content: '\e020';
}
.#{$cust-icon-prefix}-emoticon-smile:before {
  content: '\e021';
}
.#{$cust-icon-prefix}-disc:before {
  content: '\e022';
}
.#{$cust-icon-prefix}-cursor-move:before {
  content: '\e023';
}
.#{$cust-icon-prefix}-crop:before {
  content: '\e024';
}
.#{$cust-icon-prefix}-credit-card:before {
  content: '\e025';
}
.#{$cust-icon-prefix}-chemistry:before {
  content: '\e026';
}
.#{$cust-icon-prefix}-user:before {
  content: '\e005';
}
.#{$cust-icon-prefix}-speedometer:before {
  content: '\e007';
}
.#{$cust-icon-prefix}-social-youtube:before {
  content: '\e008';
}
.#{$cust-icon-prefix}-social-twitter:before {
  content: '\e009';
}
.#{$cust-icon-prefix}-social-tumblr:before {
  content: '\e00a';
}
.#{$cust-icon-prefix}-social-facebook:before {
  content: '\e00b';
}
.#{$cust-icon-prefix}-social-dropbox:before {
  content: '\e00c';
}
.#{$cust-icon-prefix}-social-dribbble:before {
  content: '\e00d';
}
.#{$cust-icon-prefix}-shield:before {
  content: '\e00e';
}
.#{$cust-icon-prefix}-screen-tablet:before {
  content: '\e00f';
}
.#{$cust-icon-prefix}-magic-wand:before {
  content: '\e017';
}
.#{$cust-icon-prefix}-hourglass:before {
  content: '\e018';
}
.#{$cust-icon-prefix}-graduation:before {
  content: '\e019';
}
.#{$cust-icon-prefix}-ghost:before {
  content: '\e01a';
}
.#{$cust-icon-prefix}-game-controller:before {
  content: '\e01b';
}
.#{$cust-icon-prefix}-fire:before {
  content: '\e01c';
}
.#{$cust-icon-prefix}-eyeglasses:before {
  content: '\e01d';
}
.#{$cust-icon-prefix}-envelope-open:before {
  content: '\e01e';
}
.#{$cust-icon-prefix}-envelope-letter:before {
  content: '\e01f';
}
.#{$cust-icon-prefix}-bell:before {
  content: '\e027';
}
.#{$cust-icon-prefix}-badge:before {
  content: '\e028';
}
.#{$cust-icon-prefix}-anchor:before {
  content: '\e029';
}
.#{$cust-icon-prefix}-wallet:before {
  content: '\e02a';
}
.#{$cust-icon-prefix}-vector:before {
  content: '\e02b';
}
.#{$cust-icon-prefix}-speech:before {
  content: '\e02c';
}
.#{$cust-icon-prefix}-puzzle:before {
  content: '\e02d';
}
.#{$cust-icon-prefix}-printer:before {
  content: '\e02e';
}
.#{$cust-icon-prefix}-present:before {
  content: '\e02f';
}
.#{$cust-icon-prefix}-playlist:before {
  content: '\e030';
}
.#{$cust-icon-prefix}-pin:before {
  content: '\e031';
}
.#{$cust-icon-prefix}-picture:before {
  content: '\e032';
}
.#{$cust-icon-prefix}-map:before {
  content: '\e033';
}
.#{$cust-icon-prefix}-layers:before {
  content: '\e034';
}
.#{$cust-icon-prefix}-handbag:before {
  content: '\e035';
}
.#{$cust-icon-prefix}-globe-alt:before {
  content: '\e036';
}
.#{$cust-icon-prefix}-globe:before {
  content: '\e037';
}
.#{$cust-icon-prefix}-frame:before {
  content: '\e038';
}
.#{$cust-icon-prefix}-folder-alt:before {
  content: '\e039';
}
.#{$cust-icon-prefix}-film:before {
  content: '\e03a';
}
.#{$cust-icon-prefix}-feed:before {
  content: '\e03b';
}
.#{$cust-icon-prefix}-earphones-alt:before {
  content: '\e03c';
}
.#{$cust-icon-prefix}-earphones:before {
  content: '\e03d';
}
.#{$cust-icon-prefix}-drop:before {
  content: '\e03e';
}
.#{$cust-icon-prefix}-drawer:before {
  content: '\e03f';
}
.#{$cust-icon-prefix}-docs:before {
  content: '\e040';
}
.#{$cust-icon-prefix}-directions:before {
  content: '\e041';
}
.#{$cust-icon-prefix}-direction:before {
  content: '\e042';
}
.#{$cust-icon-prefix}-diamond:before {
  content: '\e043';
}
.#{$cust-icon-prefix}-cup:before {
  content: '\e044';
}
.#{$cust-icon-prefix}-compass:before {
  content: '\e045';
}
.#{$cust-icon-prefix}-call-out:before {
  content: '\e046';
}
.#{$cust-icon-prefix}-call-in:before {
  content: '\e047';
}
.#{$cust-icon-prefix}-call-end:before {
  content: '\e048';
}
.#{$cust-icon-prefix}-calculator:before {
  content: '\e049';
}
.#{$cust-icon-prefix}-bubbles:before {
  content: '\e04a';
}
.#{$cust-icon-prefix}-briefcase:before {
  content: '\e04b';
}
.#{$cust-icon-prefix}-book-open:before {
  content: '\e04c';
}
.#{$cust-icon-prefix}-basket-loaded:before {
  content: '\e04d';
}
.#{$cust-icon-prefix}-basket:before {
  content: '\e04e';
}
.#{$cust-icon-prefix}-bag:before {
  content: '\e04f';
}
.#{$cust-icon-prefix}-action-undo:before {
  content: '\e050';
}
.#{$cust-icon-prefix}-action-redo:before {
  content: '\e051';
}
.#{$cust-icon-prefix}-wrench:before {
  content: '\e052';
}
.#{$cust-icon-prefix}-umbrella:before {
  content: '\e053';
}
.#{$cust-icon-prefix}-trash:before {
  content: '\e054';
}
.#{$cust-icon-prefix}-tag:before {
  content: '\e055';
}
.#{$cust-icon-prefix}-support:before {
  content: '\e056';
}
.#{$cust-icon-prefix}-size-fullscreen:before {
  content: '\e057';
}
.#{$cust-icon-prefix}-size-actual:before {
  content: '\e058';
}
.#{$cust-icon-prefix}-shuffle:before {
  content: '\e059';
}
.#{$cust-icon-prefix}-share-alt:before {
  content: '\e05a';
}
.#{$cust-icon-prefix}-share:before {
  content: '\e05b';
}
.#{$cust-icon-prefix}-rocket:before {
  content: '\e05c';
}
.#{$cust-icon-prefix}-question:before {
  content: '\e05d';
}
.#{$cust-icon-prefix}-pie-chart:before {
  content: '\e05e';
}
.#{$cust-icon-prefix}-pencil:before {
  content: '\e05f';
}
.#{$cust-icon-prefix}-note:before {
  content: '\e060';
}
.#{$cust-icon-prefix}-music-tone-alt:before {
  content: '\e061';
}
.#{$cust-icon-prefix}-music-tone:before {
  content: '\e062';
}
.#{$cust-icon-prefix}-microphone:before {
  content: '\e063';
}
.#{$cust-icon-prefix}-loop:before {
  content: '\e064';
}
.#{$cust-icon-prefix}-logout:before {
  content: '\e065';
}
.#{$cust-icon-prefix}-login:before {
  content: '\e066';
}
.#{$cust-icon-prefix}-list:before {
  content: '\e067';
}
.#{$cust-icon-prefix}-like:before {
  content: '\e068';
}
.#{$cust-icon-prefix}-home:before {
  content: '\e069';
}
.#{$cust-icon-prefix}-grid:before {
  content: '\e06a';
}
.#{$cust-icon-prefix}-graph:before {
  content: '\e06b';
}
.#{$cust-icon-prefix}-equalizer:before {
  content: '\e06c';
}
.#{$cust-icon-prefix}-dislike:before {
  content: '\e06d';
}
.#{$cust-icon-prefix}-cursor:before {
  content: '\e06e';
}
.#{$cust-icon-prefix}-control-start:before {
  content: '\e06f';
}
.#{$cust-icon-prefix}-control-rewind:before {
  content: '\e070';
}
.#{$cust-icon-prefix}-control-play:before {
  content: '\e071';
}
.#{$cust-icon-prefix}-control-pause:before {
  content: '\e072';
}
.#{$cust-icon-prefix}-control-forward:before {
  content: '\e073';
}
.#{$cust-icon-prefix}-control-end:before {
  content: '\e074';
}
.#{$cust-icon-prefix}-calendar:before {
  content: '\e075';
}
.#{$cust-icon-prefix}-bulb:before {
  content: '\e076';
}
.#{$cust-icon-prefix}-bar-chart:before {
  content: '\e077';
}
.#{$cust-icon-prefix}-arrow-up:before {
  content: '\e078';
}
.#{$cust-icon-prefix}-arrow-right:before {
  content: '\e079';
}
.#{$cust-icon-prefix}-arrow-left:before {
  content: '\e07a';
}
.#{$cust-icon-prefix}-arrow-down:before {
  content: '\e07b';
}
.#{$cust-icon-prefix}-ban:before {
  content: '\e07c';
}
.#{$cust-icon-prefix}-bubble:before {
  content: '\e07d';
}
.#{$cust-icon-prefix}-camcorder:before {
  content: '\e07e';
}
.#{$cust-icon-prefix}-camera:before {
  content: '\e07f';
}
.#{$cust-icon-prefix}-check:before {
  content: '\e080';
}
.#{$cust-icon-prefix}-clock:before {
  content: '\e081';
}
.#{$cust-icon-prefix}-close:before {
  content: '\e082';
}
.#{$cust-icon-prefix}-cloud-download:before {
  content: '\e083';
}
.#{$cust-icon-prefix}-cloud-upload:before {
  content: '\e084';
}
.#{$cust-icon-prefix}-doc:before {
  content: '\e085';
}
.#{$cust-icon-prefix}-envelope:before {
  content: '\e086';
}
.#{$cust-icon-prefix}-eye:before {
  content: '\e087';
}
.#{$cust-icon-prefix}-flag:before {
  content: '\e088';
}
.#{$cust-icon-prefix}-folder:before {
  content: '\e089';
}
.#{$cust-icon-prefix}-heart:before {
  content: '\e08a';
}
.#{$cust-icon-prefix}-info:before {
  content: '\e08b';
}
.#{$cust-icon-prefix}-key:before {
  content: '\e08c';
}
.#{$cust-icon-prefix}-link:before {
  content: '\e08d';
}
.#{$cust-icon-prefix}-lock:before {
  content: '\e08e';
}
.#{$cust-icon-prefix}-lock-open:before {
  content: '\e08f';
}
.#{$cust-icon-prefix}-magnifier:before {
  content: '\e090';
}
.#{$cust-icon-prefix}-magnifier-add:before {
  content: '\e091';
}
.#{$cust-icon-prefix}-magnifier-remove:before {
  content: '\e092';
}
.#{$cust-icon-prefix}-paper-clip:before {
  content: '\e093';
}
.#{$cust-icon-prefix}-paper-plane:before {
  content: '\e094';
}
.#{$cust-icon-prefix}-plus:before {
  content: '\e095';
}
.#{$cust-icon-prefix}-pointer:before {
  content: '\e096';
}
.#{$cust-icon-prefix}-power:before {
  content: '\e097';
}
.#{$cust-icon-prefix}-refresh:before {
  content: '\e098';
}
.#{$cust-icon-prefix}-reload:before {
  content: '\e099';
}
.#{$cust-icon-prefix}-settings:before {
  content: '\e09a';
}
.#{$cust-icon-prefix}-users:before {
  content: '\e001';
}
