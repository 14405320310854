.provision-box {
  img {
    height: 35px;
    max-width: 7.5rem;
  }
  .alert-icon {
    width: 8rem;
    text-align: center;
  }
  .h5 {
    font-weight: 400;
    color: #505050;
    font-size: 0.875rem;
  }
}
