/* modal shadow */
.modal-content {
  box-shadow: 0 0 20px 0 rgba($black, 0.2);
}

/* adjustments */
.modal-dialog {
  .modal.show & {
    transform: none;
  }
}

/* alert modal */
.modal-alert {
  padding: 0 !important; /* overriding bootstrap generated style */

  .modal-dialog {
    max-width: 100% !important; /* overriding bootstrap css for all media queries */
    display: flex;
    align-items: center;
    min-height: calc(100% - 3.5rem);
    transform: none !important; /* overriding bootstrap css */
  }

  .modal-content {
    background-color: rgba($black, 0.8);
    border-radius: 0;
    padding: 1.5rem 1rem 1rem;
  }

  .modal-title {
    font-size: 1.5rem;
    font-weight: 300;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .modal-header,
  .modal-body,
  .modal-footer {
    /*left: 20%;
		width: 60%;*/
    width: 100%;
    max-width: map-get($grid-breakpoints, lg);
    margin: 0 auto;
    padding: 0;
    color: $white;
    position: relative;
  }

  /*.modal-body {
		padding: 0.5rem 0;
	}
*/
  .close {
    color: $white;
    text-shadow: 0 1px 0 $black;
  }
}

/* transparent modal */
.modal-transparent {
  .modal-content {
    box-shadow: 0 1px 15px 1px rgba($primary-900, 0.3);
  }
  .modal-content {
    background: rgba(desaturate(darken($primary-800, 25%), 20%), 0.85);
  }
}

/* transparent backdrop */
.modal-backdrop-transparent {
  background: transparent;
}

/* fullscreen modal */
.modal-fullscreen {
  padding: 0 !important;

  .modal-content {
    border-radius: 0;
    border-width: 0;
  }

  .modal-dialog {
    max-width: calc(100vw - 40px);
    max-height: calc(100vh - 80px);

    .modal-content {
      height: calc(100vh - 80px);
    }
  }
}
.modal-dialog-center {
  min-height: calc(100% - 3.5rem);
}
/* top */
.modal-dialog-top,
.modal-dialog-bottom {
  position: absolute;
  top: 0;
  margin: 0;
  min-width: 100%;

  .modal-dialog {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }

  .modal-content {
    border-radius: 0px;
    border: 0;
  }
}

.modal-dialog-bottom {
  top: auto;
  bottom: 0;

  .modal.fade & {
    transform: translate(0, 25%);
  }
}

/* left */
.modal-dialog-left {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 !important;

  .modal-content {
    min-height: 100%;
    border-width: 0;
    border-radius: 0;
  }

  .modal.fade & {
    transform: translate(-25%, 0);
  }
}

/* right */
.modal-dialog-right {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0 !important;
  border-width: 0px;

  .modal-content {
    min-height: 100%;
    border-width: 0;
    border-radius: 0;
  }

  .modal.fade & {
    transform: translate(25%, 0);
  }
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

/* modal size */
.modal-md {
  max-width: 350px;
}

@media (min-width: 576px) {
  .modal-dialog {
    margin: 5.75rem auto;
  }
}
