.page-header {
  order: 0 !important;
}
.header-org-tenant .breadcrumb-item + .breadcrumb-item:before {
  line-height: 1.5 !important;
}
.header-org-tenant .breadcrumb {
  margin-bottom: 0px;
}

.form-group.header-select-ntu {
  margin-bottom: 0;
}

.ntu-dropdown-show {
  position: absolute;
  top: 45px;
  left: 102px;
  will-change: top, left;
}

.header-org-selector {
  display: flex;
}

.org-dropdown {
  background-color: #f6f6f6;
  border-radius: 4px;
  margin-right: 10px;
}

.org-dropdown span.fa-sitemap {
  font-size: 1.2rem;
}

.org-dropdown .dropdown-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

.org-dropdown .dropdown-multilevel:last-of-type > .dropdown-item {
  border-bottom: none;
}
@media (max-width: 576px) {
  .header-org-selector.hidden-md-up {
    padding: 0 1rem;
    margin-top: 0.5rem;
  }
}
@media (min-width: 577px) {
  .header-org-selector.hidden-md-up {
    padding: 0 1.5rem;
    margin-top: 0.5rem;
  }
}
.header-org-selector.hidden-md-up .org-dropdown {
  max-height: 43px;
}

.header-org-selector.hidden-md-up .org-dropdown .dropdown-item {
  padding: 0.75rem 1.2rem;
}
.header-org-selector.hidden-md-up .breadcrumb {
  padding: 0.2rem 0.5rem;
  width: 100%;
  align-items: center;
}
.header-org-selector.hidden-md-up .breadcrumb > li.breadcrumb-item {
  font-size: 0.7rem;
  max-width: 100%;
}

.ntu-status-icon {
  margin-left: 10px;
}

.header-icon:not(.btn) .profile-image.profile-image-cust {
  width: 3.125rem;
  height: auto;
}
